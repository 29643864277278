.container {
    border-radius: 20px;
    background-color: #fff;
    // box-shadow: 0px 2px 5px rgba(112, 144, 176, 0.3);
    padding: 20px;
    position: relative;
}
.title {
    font-size: 19px;
    font-weight: 600;
    color: #000;
}
.subtitle {
    font-size: 19px;
    font-weight: 500;
    color: #424242;
}
.para {
    font-size: 19px;
    font-weight: 500;
    color: #757575;
    display: block;
    margin-top: 25px;
}
.editButton {
    position: absolute;
    right: 30px;
    top: 30px;
}
