
.tooltipBox {
    cursor: pointer;
    display: flex;
}

.tooltip {
    width: 213px;
    flex-shrink: 0;
    background-color: #FFF;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.10);
    padding: 15px;
    border-radius: 8px;
    position: absolute;
    transform: translateY(10px);
    opacity: 0;
    visibility: hidden;
    top: 15px;

    .title {
        color: #000;
        font-size: 11px;
        font-weight: 600;
        line-height: 138%;
    }

    .detail {
        color: #8B8989;
        font-size: 11px;
        font-weight: 500;
        line-height: 138%;
    }
}

.tooltipBox:hover .tooltip {
    transform: translateY(0px);
    opacity: 1;
    visibility: visible;
    transition-duration: .5s;
}