.mainContainer {
    border-radius: 20px;
    background: linear-gradient(#fff, #fff), #fff;
    // box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.useBox {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    border-width: 4px;
    border-style: solid;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .userImg {
        width: 100%;
    }
}

.userInfo {
    display: flex;
    align-items: center;
    gap: 15px;
}

.flexText {
    display: block;
    color: #838383;
    font-size: 16px;
}

.boldText {
    display: inline;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
}

.textTime {
    font-size: 15px;
    font-weight: 600;
}

.nexText {
    font-size: 17px;
    color: #43cc2b;
    font-weight: 700;
}

.flexRow {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 16px;
}

.title {
    font-size: 30px;
    color: #000;
}

.paraContainer {
    margin-top: 16px;
    margin-bottom: 16px;

    br {
        display: none;
    }

    h1 {
        font-size: 18px;
        font-weight: 500;
    }

    p {
        font-size: 15px;
        font-weight: 500;
    }
}

.para {
    font-size: 19px;
    font-weight: 500;
    color: #757575;
    padding-bottom: 15px;
}

.activeBox {
    border-radius: 20px;
    background-color: #fff;
    border: 1px solid #e2e2e2;
    // box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);

    .statusInfo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 35px;
        border-radius: 20px 20px 0px 0px;
        background: #FBFBFF;
    }

    .projectInfo {
        padding: 20px 35px;
    }
}

.expiresSoon {
    color: rgba(255, 120, 0, 0.50);
}

.quoted {
    font-size: 13px;
    color: #424242;
}

.b {
    font-size: 13px;
    color: #000;
    font-weight: 600;
}

.flex1 {
    display: flex;
    align-items: flex-end;
    gap: 5px;
}

.flex2 {
    display: flex;
    align-items: center;
    gap: 5px;
}

.flexJustify {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.text1 {
    font-size: 23px;
    font-weight: 600;
    color: #000;
}

.text2 {
    font-size: 17px;
    font-weight: 600;
    color: #000;
}

@media(max-width:767px) {
    .projectInfo {
        > div {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 15px;
        }

        div, button {
            width: 100% !important;
        }
    }
}