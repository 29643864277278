.formRow {
    border-radius: 10px;
    background: #FFF;
    padding: 34px;
}

.sectionTitle {
    font-size: 20px;
    font-weight: 600;
    display: flex;
    gap: 5px;
    align-items: center;
    margin-bottom: 34px;
}

.inputLabel {
    font-size: 16px;
    font-weight: 590;
    margin-bottom: 5px;
}

.checkboxLabel {
    span {
        font-size: 13px !important;
        font-weight: 600 !important;
        line-height: 14px !important;
    }
}

.buttonArea {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.progressValue {
    color: #FFF;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    position: absolute;
    z-index: 9;
    top: 6px;
}

.formTitle {
    font-size: 19px;
    font-weight: 500;
    margin-bottom: 45px;
}

.iconText {
    font-size: 18px;    
    font-weight: 600;
    margin-top: 15px;
}

.formLogoImg {
    border: 1px solid #C5C5C5;
}