.wrapper {
  background: #fff;
  padding: 10px;
  border: 1px solid #C5C5C5;
  border-radius: 10px;

  & .MuiInputBase-input::placeholder,
  & .MuiInputLabel-root {
    font-size: 14px;
    color: #777777;
  }
}

.serviceWrapper {
  background: #fff;
  padding: 10px;

  & .MuiInputBase-input::placeholder,
  & .MuiInputLabel-root {
    font-size: 14px;
    color: #777777;
  }
}

.flexWrapper {
  display: flex;
}

.priceWrapper {
  display: flex;
  align-items: flex-end;
}

.priceBreakdown {
  color: #0085DD;
  cursor: pointer;
}

.priceInput fieldset {
  height: 50px !important;
}

.cursorPointer {
  cursor: pointer
}

.editBtn {
  & .MuiIcon-root {
    font-size: 1rem;
  }
}

.section {
  background: #F6F6F6;
  border-radius: 10px;
  padding: 10px;
  margin: 0;
  position: relative;
}

.infoWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.serviceField {
  width: 30% !important;
  min-width: 200px !important;
}

.info {
  margin-right: 10px;
  word-break: keep-all;
  white-space: nowrap;
}

.serviceTag {
  width: fit-content;
  margin: 8px 10px 4px 0;
  display: flex;
  align-items: center;
  background: #EBEBEB;
  padding: 10px;
  border: none;
  border-radius: 8px;
  height: 40px;
  font-size: 12px;
  line-height: 138%;
  color: #777777;
}

.fieldWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.sectionInfo {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  line-height: 24px;
  align-items: center;
  gap: 5px;
}

.inputWrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0 0;

  & .MuiInputLabel-root {
    font-size: 30px;
    color: #777777;
  }
}

.serviceInputWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px 0;
  justify-content: space-between;

  & .MuiInputLabel-root {
    font-size: 30px;
    color: #777777;
  }
}

.CloseIcon {
  margin: 0 12px;
  padding: 0;
}

.bold {
  font-weight: 600;
}

.subText {
  color: #777;
}

.closeIcon {
  color: #A4A4A4;
  float: right;
  cursor: pointer;
  right: 17px;
  width: 24px;
  height: 24px;
  top: 16px;
}

.dialog {
  & label {
    & .MuiTypography-body1 {
      font-size: 0.875rem;
    }
  }

  & .MuiDialog-paper {
    padding: 34px 52px;
    height: auto;
    max-height: 92vh;
    width: 754px;
    overflow: auto;
    margin: 0;
    position: relative;
  }
}

.backBtn {
  font-size: 24px !important;
  line-height: 28.64px;
  font-weight: 600;
  text-transform: capitalize !important;
  color: #222222 !important;

  &:hover {
    background-color: #fff !important;
  }
}

.backBtn span {
  margin: 0;
}

.backIcon {
  font-size: 28px !important;
  margin: 0;
  line-height: 32px !important;
}

.breakDownHeading {
  display: flex;
  background: #ECEBEB;
  justify-content: space-between;
  padding: 7px 24px;

  & h6 {
    font-size: 10px;
  }
}

.breakDownPrcingTitle {
  display: flex;
  justify-content: space-between;
  width: 190px;
  align-items: center;
}

.breakDownContent {
  display: flex;
  background: #F9F9F9;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #D7D7D7;
}

.brTitle1 {
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  color: #545454;
  // width: 80px;
  text-align: right;
}

.brTitle2 {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  // width: 80px;
  text-align: right;
}

.brTitle3 {
  color: #000;
  font-size: 12px;
  font-weight: 510;
  position: relative;
}

.brTitle4 {
  font-size: 12px;
  font-weight: 510;
  padding-left: 10px;
  color: rgb(89, 89, 89);
}

.brTitle5 {
  font-size: 14px;
  line-height: 14px;
  color: #777777;
  min-width: 80px;
  font-weight: 510;
}

.brTitle6 {
  font-size: 14px;
  line-height: 16px;
  font-weight: 510;
  color: #000;
  margin-left: 10px;
}

.brContent {
  padding: 15px;
}

.listArea {
  display: flex;
  align-items: center;
}

.listArea p {
  text-align: left;
  display: flex;
}

.listItem {
  margin: 0;
  padding: 0 20px;
  color: #595959;
}

.flexEnd {
  display: flex;
  justify-content: flex-end;
}

.totalContent {
  display: flex;
  justify-content: flex-end;
  background: #F6F6F6;
  padding: 10px 15px;
  border-radius: 5px;
  margin: 0 -15px;
  margin-top: 10px;
}

.totalTitle1 {
  font-size: 14px;
  color: #424242;
  font-weight: 510;
}

.totalTitle2 {
  font-size: 14px;
  color: #000;
  font-weight: 600;
  margin-left: 10px;
}


// Architectural CSS

.serviceSelection {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.detailQuoteLink {
  color: #0085DD !important;
  font-size: 14px !important;
  font-weight: 590 !important;
  text-transform: capitalize !important;
  cursor: pointer;
  line-height: 1 !important;
}

.detailQuoteLink:hover {
  background: none !important;
}

.measurementTitle {
  display: flex;
  background: #F8F8F8;
  padding: 11px;
  font-size: 14px;
  color: #545454;
  font-weight: 700;
}

.windows {
  width: 15%;
  display: flex;
  justify-content: flex-start;
}

.measurement {
  width: 20%;
  display: flex;
  justify-content: center;
}

.total_sqft {
  width: 25%;
  display: flex;
  justify-content: flex-end;
}

.servicePadding {
  padding: 16px 8px !important;
}

.measurementDetails {
  display: flex;
  padding: 11px;
  font-size: 14px;
  color: #545454;
  font-weight: 700;
  align-items: center;
}

.measurementDetailsBorder {
  border-bottom: 1px solid #C5C5C5;
}

.windowsInput {
  width: 15%;
  display: flex;
  justify-content: flex-start;

  div div {
    width: 60%;
  }

  div div input {
    background: #F8F8F8;
    border: none;
    border-radius: 5px;
  }

  div div fieldset {
    border: none;
  }

  p {
    width: 50px;
    padding-left: 10px;
  }
}

.measurementInput {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    align-items: center;
  }

  div div {
    width: 70%;
  }

  div div input {
    background: #F8F8F8;
    border: none;
    border-radius: 5px;
  }

  div div fieldset {
    border: none;
  }

  p,
  label {
    width: 40px;
  }

}

.totalSqftInput {
  width: 25%;
  display: flex;
  justify-content: flex-end;

  div div {
    width: 50%;
  }
}

.measurementList {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
}

.addRow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 14px;
}

.priceBreakdown {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 14px;

  p {
    padding-left: 10px;
  }
}

.measurementInfo {
  display: flex;

  span {
    width: 120px;
  }
}

.filledSection {
  display: flex;
  flex-direction: row;
  gap: 25px;
  align-items: flex-end; 
}


.filledSection .MuiFormControl-root {
  margin-top: 0;
  margin: 0;
}

.filledSection>div {
  width: 200px;
}

.price_per_sqft {
  width: 120px !important;
}

.priceInput {
  width: 101px !important;
}

.beforePrice {
  width: 180px;
}

.discountPriceInput {
  width: 101px !important;
}

.discountPriceInput>div {
  background-color: #FFE4E4;
}

.subTotalWrapper {
  display: flex;
  gap: 25px;
}

.applicationWrapper {
  padding: 0 10px;
}

.detailHeader {
  color: #000;
  font-size: 23px;
  font-weight: 600;
  line-height: 138%;
}

.detailContent {
  color: #222;
  font-size: 17px;
  font-weight: 500;
  line-height: 21px;
  margin-top: 18px;
  margin-bottom: 6px;
}

.dialogActions {
  padding: 0;
  display: block;
  margin-top: 25px;
}

.detailBtn {
  font-size: 17px;
  font-weight: 600;
  line-height: 138%;
  letter-spacing: 0em;
  text-align: center;
  padding: 14px 89px;
}

.closeIcon {
  color: #A4A4A4;
  float: right;
  cursor: pointer;
  position: absolute;
  top: 14px;
  right: 17px;
}

.closeCircleIcon {
  margin-left: -15px;
  cursor: pointer;
}

.arcPriceDisplay {
  line-height: 1.4375em;
  padding: 10px 10px 10px 0;
  margin-top: 7px;
  margin-bottom: 4px;
  span {
    font-size: 17px;
  }
}

.gridWrapper {
  display: flex;
  text-align: right;
}

.confirmDialog {
  padding: 35px 50px 35px 50px;
  position: relative;
}

.btnBack, .backBtn:hover {
  color: #FFF !important;
  text-align: center;
  font-size: 17px !important;
  font-weight: 600;
  line-height: 138%;
  padding: 14px 39px;
  background-color: #000 !important;
}

.btnWrapper {
  display: flex;
  gap: 8px;
}

.modelCloseIcon {
  cursor: pointer;
  position: absolute;
  top: -15px;
  right: -15px;
}

.serviceName {
  color: #000;
  font-size: 14px;
  font-weight: 590;
}


.dialogContent {
  position: relative;
}