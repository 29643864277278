.selectTeamMember {
    // width: 70px;
    height: 42px;
    border-radius: 5px;
    border: 0.5px solid #D2D2D2;
    background: #F6F6F6;
    fieldset {
        display: none;
    }
}

.selectedIcon {
    height: 25px;
    width: 25px;
}

.scheduleUser {
    display: flex;
    align-items: flex-end;
}


.checkIn {
    .selectTeamMember {
        width: 54px;
        height: 38px;
        border: none;
        background-color: #fff;

        .selectedIcon {
            margin-left: 7px;
            height: 23px;
            width: 23px;
        }
    }

    .selectTeamMember > div {
        padding: 0 !important;
    }

    .selectTeamMember > svg {
        right: 0;
    }
}

.modifyProject {
    .selectTeamMember {
        width: 94px;
        height: 38px;
        border: 1px solid #ECECEC;
        background-color: #fff;

        .selectedIcon {
            margin-left: 0;
        }
    }

    .selectTeamMember > div {
        padding: 0 !important;
        padding-left: 7px !important;
    }
}

.schedule {
    .selectTeamMember {
        width: 54px;
        height: 42px;

        .selectedIcon {
            margin-left: 7px;
            height: 23px;
            width: 23px;
        }
    }

    .selectTeamMember > div {
        padding: 0 !important;
    }

    .selectTeamMember > svg {
        right: 0;
    }
}
