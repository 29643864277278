.container {
    border-radius: 20px;
    background-color: #fff;
    // box-shadow: 0px 2px 5px rgba(112, 144, 176, 0.3);
    padding: 20px;
    position: relative;
}
.title {
    display: block;
    font-size: 23px;
    font-weight: 600;
}
.lable {
    font-size: 15px;
    font-weight: 600;
    color: #424242;
}
.data {
    font-size: 15px;
    font-weight: 500;
    color: #000;
}
.gridFlex {
    display: grid;
    grid-template-columns: 0.5fr 2fr;
    padding-top: 20px;
}
