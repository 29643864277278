.close {
    position: absolute;
    right: 10px;
    top: 8px;
}
.title {
    padding-bottom: 25px;
    display: block;
    color: #222222;
}
.imageCrop {
    border: 1px solid #c5c5c5;
}
.title2 {
    font-size: 19px;
    font-weight: 600;
    color: #1f1f1f;
}
.para {
    font-size: 14px;
    font-weight: 500;
    color: #424242;
}
.imgBox {
    display: flex;
    gap: 15px;
    .imgBlack {
        position: relative;
        border: 1px solid #c5c5c5;
        width: 126px;
        height: 126px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .imgWhite {
        position: relative;
        border: 1px solid #c5c5c5;
        width: 126px;
        height: 126px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.flexRow {
    display: flex;
    gap: 20px;
}
.imgView {
    width: 100%;
}
