.modal {
    display: flex;
    align-items: center;
    justify-content: center;
}
.paperModal {
    position: relative;
    background-color: white;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.15);
    padding: 20px;
    max-width: 400px;
    width: 100%;
}
.title {
    font-size: 14px;
    line-height: 138%;
    font-weight: 600;
}
.subtitle {
    font-size: 14px;
    padding-bottom: 15px;
    font-weight: 500;
    color: #000;
    display: block;
}
.close {
    position: absolute;
    right: 10px;
    top: 8px;
}
.button {
    width: 100%;
    line-height: 1;
}
