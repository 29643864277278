.sectionWrappers .MuiInputBase-input::placeholder .MuiInputLabel-root {
  font-size: 14px;
  color: #777777;
}

.flexWrapper {
  display: flex;
  width: 100%;
  gap: 10px;
}

// .flexWrapperSpacing {
//   margin: 8px !important;
// }

.inputBold .MuiOutlinedInput-input {
  font-weight: 600 !important;
}