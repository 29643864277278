.wrapper {
  background: #fff;
  display: flex;
  border: 1px solid #C5C5C5;
  border-radius: 6px;  
  justify-content: space-between;
  align-items: center;
  padding: 6px 13px;
  margin-top: 14px;
  position: relative;
}

.wrapper label {
  display: flex;
}

.flexWrapper {
  display: flex;
}

.film_removal {
  margin-left: 15px !important;
}

.filmInfo {
  display: flex;
  padding: 1px;
  align-items: center;
  margin-bottom: 10px;
}

.filmWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  border: none !important;
}

.filmWrapper .MuiOutlinedInput-notchedOutline {
  border-color: #fff;
  border: none !important;
}

.selected {
  opacity: 0.7;
}

.selectField {
  height: fit-content;
  border: none !important;
}

.selectField fieldset {
  height: fit-content;
  border: none !important;
}

.cancelIcon {
  color: #a4a4a4;
  padding: 0 12px;
  position: absolute;
  right: -45px;
}

.textField {
  margin-left: 10px;
}

.multiOption {
  background: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border: none;
  border-radius: 10px;
  padding: 5px 0;
}

.iconBtn {
  border-radius: 8px;
  margin: 0 5px;
}

.iconBtn:hover {
  opacity: 0.7;
}

.iconBtn {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
}

.selectedBtn {
  border-radius: 8px;
  margin: 0 5px;
  background: linear-gradient(90deg, hsla(282, 93%, 22%, 1) 0%, hsla(302, 96%, 28%, 1) 100%);
}

.selectedBtn:hover {
  opacity: 0.7;
}

.selectedBtn {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
}

.imageIcon {
  display: flex;
  height: inherit;
  width: inherit;
}

.disabledMenuItem {
  opacity: 1 !important;
}

.selectFilmText {
  color: #D1D1D1;
  font-size: 11px;
  font-weight: 600;
}

.modifyProject {
  .wrapper {
    display: block;
    padding: 0;
    border: none;
  }

  .filmWrapper {
    justify-content: flex-start;
    gap: 15px;
    margin-top: 5px;
  }

  .selectField {
    border: 1px solid #ECECEC !important;
    border-radius: 7px;

    input {
      padding: 0 !important;
    }
  }

  .cancelIcon {
    padding: 0 12px;
    position: initial;
  }
}

.editProject {
  width: 100%;
}