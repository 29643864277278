.root {
  z-index: 1;
  background: #fff;
  width: 47px;
  height: 47px;
  display: flex;
  border-radius: 50%;
  border: 2px solid transparent;
  justify-content: center;
  align-items: center;
}

.root svg {
  font-size: 1.25rem;
  stroke: #757575;
  fill: none;
}

.active {
  border-color: #892d8e;
  color: #892d8e;
}

.active svg {
  stroke: #892d8e;
  fill: none;
}

// .completed {
//   background-image: linear-gradient(90deg, #892d8e 0%, #892d8e 93.31%);
//   color: #fff;
// }

// .completed svg {
//   stroke: #fff;
//   fill: #892d8e;
// }

// .active {
//   border-color: linear-gradient(270deg, #902F8C 4.58%, #69338D 100%);
//   color: linear-gradient(270deg, #902F8C 4.58%, #69338D 100%);
// }

.active svg {
  stroke: linear-gradient(270deg, #902F8C 4.58%, #69338D 100%);
  fill: none;
}

.completed {
  background-image: linear-gradient(270deg, #902F8C 4.58%, #69338D 100%);
  color: #fff;
}

.completed svg {
  stroke: #fff;
  fill: linear-gradient(270deg, #902F8C 4.58%, #69338D 100%);
}

.root2 {
  width: 100%;
}

.stepper {
  background: #F6F6F6;
  border-radius: 10px;
  padding: 17px 26px;
}

.stepper .MuiStep-horizontal {
  padding: 0;
}

.stepper .MuiStepLabel-iconContainer {
  padding-right: 0;
}

.stepper .MuiStepConnector-line {
  height: 2px;
  border-color: #fff;
}

