.scheduleWrapper {
  border-radius: 7px;
  border: 1px solid #C5C5C5;
  padding: 17px 25px;
}

.scheduleTiming {
  border-radius: 10px;
  padding: 10px 0;
}

.scheduleDate,
.scheduleTime {
  .scheduleDatePicker {
    border-radius: 5px;
    border: 0.5px solid #D2D2D2;
    background: rgba(246, 246, 246, 1);
    min-width: 50px;

    >div {
      font-weight: 600;
      font-size: 15px;
    }
  }

  input {
    padding: 12px 18px;
    width: 100px;
  }
}

.scheduleTime>div {
  padding: 0;
}

.scheduleTime {
  margin-left: 16px;

  .scheduleDatePicker,
  input {
    min-width: 50px;
    width: 150px;
  }

  input {
    padding: 9px 0 9px 18px;
  }
}

.scheduleTiming {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.scheduleDuration {
  display: flex;
  justify-content: flex-start;
}

.selectTeamMember {
  width: 67px;
  height: 49px;
  border-radius: 5px;
  border: 0.5px solid #D2D2D2;
  background: #F6F6F6;
  margin-left: 16px;
}

.selectedIcon {
  height: 25px;
  width: 25px;
}

.scheduleUser {
  display: flex;
  align-items: flex-end;
}

.calendarLink {
  color: #0085DD !important;
  font-size: 14px !important;
  font-weight: 590 !important;
  text-transform: capitalize !important;

}

.calendarLink:hover {
  background: none !important;
}

.flexWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.inputFieldSelect {
  width: 100%;
  margin-bottom: 8px;
  padding: 0px 8px;
  border-radius: 8px;
  background: rgba(246, 246, 246, 1);
}

.workshopItem {
  display: flex;
  align-items: center;

  span {
    margin-left: 10px;
  }
}

.addLocationBtn {
  color: #0085DD;
  font-size: 14px;
  font-weight: 510;
  padding: 0;
  text-transform: capitalize;
}

.appointmentLocation {
  border-radius: 5px;
  border: 0.5px solid #D2D2D2;
  background: #F6F6F6;
  padding: 10px 12px;
  max-width: 452px;
}