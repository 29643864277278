.datepicker {
    border-radius: 5px;
    border: 0.5px solid #D2D2D2;
    background: #F6F6F6;
    color: #242424;
    padding: 10px 12px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 185px;
    font-family: 'Inter';
    outline: none;
    cursor: pointer;
}