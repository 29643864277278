.model {
    background-color: #FFF;
    box-shadow: 0px 4px 5px 0px rgba(112, 144, 176, 0.10);
    border-radius: 10px;
    padding: 30px 35px 35px 35px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.modelTitle {
    color: #000;
    font-size: 23px;
    font-weight: 600;
    line-height: 138%;
}

.modelHeader {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.selectLocation {
    border-radius: 5px;
    border: 0.5px solid #D2D2D2;
    background: #F6F6F6;
    color: #1F1F1F;
    font-size: 14px;
    font-weight: 590;
    margin-top: 10px;

    input {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    div {
        padding: 9px;
    }

    :disabled {
        background: #F6F6F6;
        color: #1F1F1F;
    }
}

.customerInfoForm {
    input {
        border-radius: 7px;
        border: 1.5px solid #E4E4E4;
        padding: 10px 15px;
        background: #FFF;
        font-size: 16px;
        font-weight: 510;
    }

    input::placeholder {
        color: #D1D1D1;
    }
}

.inputLabel {
    color: #000;
    font-size: 16px;
    font-weight: 510;
    line-height: 30px;
}

.saveChangeBtn {
    background-color: linear-gradient(270deg, #902F8C 0%, #69338D 100%);
    text-transform: capitalize;
    border-radius: 7px;
    padding: 7px 30px;
    font-size: 16px;
    font-weight: 600;
    margin-top: 15px;
}

.modelCloseIcon {
    cursor: pointer;
    position: absolute;
    right: -15px;
    top: -15px;
}

.InputBorder {
    border: 1.5px;
    border-radius: 6px;
    background-color: #FFF;

    input {
        border: none;
        padding: 6px 9px !important;
    }
}