.sectionWrappers {
  & .MuiFormControlLabel-root {
    word-break: keep-all;
    white-space: nowrap;
  }
}

.MuiTypography-subtitle2 {
  font-size: 0.8rem;
}

.textFieldWrapper {
  display: flex;
}

.textField {
  margin-bottom: 10px;
  margin-right: 20px !important;
  & .MuiInputBase-input::placeholder {
    font-size: 12px;
  }
}

.selectBox {
  flex: 0 50%;
  & .MuiFormControlLabel-label.Mui-disabled {
    color: #000000;
  }
}

.selectBoxIcon {
  position: relative;
  & img {
    position: absolute;
    left: -26px;
    top: 2px;
  }
}

.error {
  color: #ef6806;
}

.sectionWrappers {
  margin-top: 24px;
}

.closeIcon {
  color: #A4A4A4;
  float: right;
  cursor: pointer;
}

.closeButton {
  position: absolute;
  right: 20px;
  top: 13px;
  color: #9e9e9e;
}

.useContactBtn {
  padding: 7px 22px;
  color: #fff;
  background: linear-gradient(90deg, #78308E 0%, #8A2D8E 93.31%);
  border: none;
  border-radius: 7px;
  text-transform: capitalize;
  margin-top: 13px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.useContactBtn:hover {
  background: linear-gradient(90deg, #78308E 0%, #8A2D8E 93.31%);
}

.exitDialogActions {
  padding: 0 24px 32px 24px;
}

.accordion {
  box-shadow: none;
  border-radius: none;
  min-height: 50px;
}

.contactDetails {
  background: #ffffff;
  border: 1px solid #c8c8c8;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  left: 16px;
  right: 16px;
  display: block;
  padding: 14px 20px;
}

.accordionSummary {
  background: #ffffff;
  border: 1px solid #c8c8c8;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.infoWrapper {
  display: flex;
  margin-bottom: 18px;
  align-items: center;
}

.info {
  margin-left: 13px;
  color: #000000;
}

.infoTitle {
  margin-bottom: 0.6rem;
  font-size: 18px;
}

.quoteInfo {
  color: #000000;
  display: flex;
  padding: 14px 0 34px 0;
}

.quoteWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
}

.chip {
  margin-left: 70px;
  font-size: 10px;
  line-height: 18px;
  height: 21px;
}

.infoCaption {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #797979;
}

.divider {
  text-align: center;
  position: relative;
  width: max-content;
  margin-top: 20px;
  margin-bottom: 15px;
  color: #000000;
  font-weight: 600;
}

.divider::after,
.divider::before {
  content: '';
  display: block;
  position: absolute;
  background-color: #cecece;
  width: 25%;
  height: 1px;
}

.divider::after {
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.divider::before {
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.inputFieldSelect {
  width: 100%;
  margin-bottom: 8px;
  padding: 0px 8px;
}

.inputFieldSelect .MuiInputBase-input {
  padding: 10px;
  height: 5px;
  border: 1px solid #c8c8c8;
  border-radius: 8px;
}

.inputFieldSelect .Mui-disabled {
  background: #f7f7f7;
}

.inputFieldSelect .MuiOutlinedInput-root {
  border: none;
  border-radius: 10px;
}

.inputFieldSelect .MuiOutlinedInput-notchedOutline {
  border: none;
}

.sectionWrappers .MuiInputBase-input::placeholder {
  font-size: 14px;
  color: #777777;
}

.autoCompletOPtions {
  padding: 3px 10px;
  justify-content: space-between;
  display: flex;
  width: 100%;
}

.autoCompletOPtions img {
  width: 20px;
}

.roleCheckbox span{
    background-color: #C5C5C5
}
