.btnFooterWrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  width: 100%;
  padding: 24px 48px;
}

.btnFooterWrapper .MuiButton-root {
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: 400;
}

.insideBtnWrapper {
  display: flex;
  gap: 10px;
}

.backBtn {
  color: #000;
  text-transform: capitalize;
  font-size: 18px;
}

.backBtn:hover {
  background: none;
}

.nextBtn {
  padding: 7px 22px !important;
  font-weight: 600 !important;
  font-size: 14px;
}

.nextBtn:hover {
  background: linear-gradient(90deg, #78308E 0%, #8A2D8E 93.31%);
}

.nextBtn .Mui-disabled {
  background: #0001ff !important;
  opacity: 1;
}

.nextBtn .MuiButton-endIcon {
  margin-left: 0;
}

.scheduleBtn {
  font-size: 14px !important;
}

.scheduleBtn:hover {
  background: linear-gradient(90deg, #2B4291 0%, #32A5DB 100%);
}

.scheduleBtn .Mui-disabled {
  background: #00011f !important;
  opacity: 1;
}

.scheduleBtn .MuiButton-endIcon {
  margin-left: 0;
}

.progress {
  position: absolute;
}

.finishBtnMenu .MuiMenu-paper {
  border-radius: 8px;
  padding: 10px 10px 0 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 217.5%;
}

.finishBtn:hover {
  background: linear-gradient(90deg, #78308E 0%, #8A2D8E 93.31%);
  border-radius: 6px;
  color: #fff;
}

.model {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 4px 5px 0px rgba(112, 144, 176, 0.10);
  padding: 37px 52px 23px 51px;
  position: relative;

  .modelTitle {
    color: #222;
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .modelCloseIcon {
    cursor: pointer;
    position: absolute;
    top: 13px;
    right: 21px;

    img {
      width: 12px;
      height: 12px;
    }
  }

  .modelInformation {
    color: #222;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 20px;
    margin-bottom: 31px;
    width: 455px;
  }


  .modelFooter {
    display: flex;
    align-items: center;
  }

  .backBtn {
    width: 146px;
    height: 52px;
    background-color: #000;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 138%;
    border-radius: 7px;

  }

  .continueBtn {
    width: 301px;
    height: 52px;
    flex-shrink: 0;
    background: linear-gradient(270deg, #902F8C 4.58%, #69338D 100%);
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 138%;
    border-radius: 7px;
    text-transform: capitalize;
    margin-left: 8px;
  }
}