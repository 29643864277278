.tooltipIcon {
    position: relative;
    cursor: pointer;
    line-height: 21px;
    height: 25px;
    display: flex;
    align-items: center;
    // transform: scale(0.8);
}

.tooltipIcon:hover .tooltipText {
    transform: translateY(0px);
    opacity: 1;
    visibility: visible;
    transition-duration: .5s;
}

.tooltipText {
    position: absolute;
    background-color: #fff;
    z-index: 9;
    padding: 5px 10px;
    max-width: 250px;
    min-width: 250px;
    top: 25px;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.10);
    border-radius: 8px;
    color: #242424;
    font-size: 14px;
    font-weight: normal;
    display: block;
    transform: translateY(10px);
    opacity: 0;
    visibility: hidden;
    text-transform: math-auto;
}