.dialog {
  & label {
    & .MuiTypography-body1 {
      font-size: 0.875rem;
    }
  }

  & .MuiDialog-paper {
    padding: 34px 52px;
    height: auto;
    max-height: 92vh;
    width: 754px;
    overflow: auto;
    margin: 0;
    position: relative;
  }
}

.dialogActions {
  padding: 0;
}

.header {
  margin-top:10px;
}

.btnQuick {
  background: #fff;
  color: #000;
  font-size: 16px;
  padding: 7px 20px;
  font-weight: 600;
  text-transform: none;
  border-radius: 6px;
  font-family: 'Source Sans Pro';
  letter-spacing: normal;

  &:hover {
    background-color: #fff;
  }
}

.title {
  color: #000;
}

.widthSelect {
  align-self: flex-end;
  margin: 0 20px 20px;
}

.subText {
  color: #949494;
}

.dialogContent {
  padding: 30px 45px 40px 45px !important;
}

.closeIcon {
  color: #A4A4A4;
  float: right;
  cursor: pointer;
  position: absolute;
  right: 17px;
  width: 26px;
  height: 26px;
  top: 16px;
}

.exitDialog {
  & .MuiTypography-root {
    font-size: 14px;
    font-weight: 600;
    line-height: 138%;
  }

  & .MuiDialogContent-root {
    font-size: 14px;
    font-weight: 400;
    line-height: 138%;
  }
}

.btnDelete {
  width: 40%;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.btnSave {
  width: 60%;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.exitDialogActions {
  justify-content: space-evenly;
  padding: 20px 20px;
}

.closeButton {
  position: absolute;
  right: 20px;
  top: 13px;
  color: '#000';
}