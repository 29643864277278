.inputLabel {
    color: #2E2E2E;
    font-size: 16px;
    font-weight: 590;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.SelectField {
    border-radius: 7px;
    border: 1px solid #C5C5C5;
    width: 100%;
    overflow: hidden;

    div {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    svg {
        font-size: 25px;
    }

    fieldset {
        border: none;
    }
}

.task { 
    .SelectField {
        color: var(--Dark-light, #242424);
        font-size: 13px;
        font-weight: 600;
        min-width: 211px;
    }   

}

.task.selectOptions {
    color: #000;
    font-size: 13px;
    font-weight: 600;
}

.selectLocation1 {
    padding: 0 !important;
}

.selectLocation {
    width: 150%;
    overflow: hidden;
}