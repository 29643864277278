.timeDisplay {
    border-radius: 5px;
    border: 0.5px solid #D2D2D2;
    background: #F6F6F6;
    color: var(--Dark-light, #242424);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 10px 12px;
    cursor: pointer;
}

.popperDropdown {
    border-radius: 10px;
    background: #F6F6F6;
    padding: 10px 7px;
    height: 252px;
}

.timeText {
    color: #000;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 5px;
    padding-left: 10px;
    cursor: pointer;
    min-width: 100px;
}

.timeList {
    border-radius: 5px;
    background: rgba(42, 68, 150, 0.05);
    overflow: auto;
    height: 191px;
    margin-top: 5px;
    padding: 0;

    li {
        color: #1F1F1F;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        padding-right: 15px;
        padding-left: 8px;
        padding-top: 0;
        padding-bottom: 0;

        span {
            color: #1F1F1F;
            font-size: 12px;
            font-style: normal;
            font-weight: 590;
            line-height: normal;
            margin-left: 5px;
        }
    }
}

.timePickerDropdown {
    // min-width: 95px;
    position: relative;
}

/* width */
.timeList::-webkit-scrollbar {
    width: 6px;
    
}

/* Track */
.timeList::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #AEAEAE;
    cursor: pointer;
}

.endTime {
    position: relative;

}

.endTime:before {
    content: 'to';
    position: absolute;
    left: -20px;
    color: #AEAEAE;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.selectedLink {
    background-color: rgba(0, 0, 0, 0.04);
}

.timePickerDropdownBody {
    display: flex;
    justify-content: space-between;
    height: 195px;
}