.header {
    display: flex;
    align-items: center;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    .logoBox {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .headerInner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        width: 100%;
    }
}

.headerIcon {
    gap: 17px;
    span {
        cursor: pointer;
    }
}

.headerRight {
    display: flex;
    gap: 30px;
    align-items: center;
}
.userBox {
    display: flex;
    gap: 12px;
    align-items: center;
    img {
        cursor: pointer;
    }
}
.headerLeft {
    display: flex;
    gap: 34px;
}
.menuIcon {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.flexCenter {
    display: flex;
    align-items: center;
}
.flexInner {
    display: grid;
    align-items: center;
    grid-template-columns: 80px auto;
}
.menuScroll {
    max-height: 398px;
    overflow: hidden;
    overflow-y: auto;
    padding-right: 12px;
}
/* width */
.menuScroll::-webkit-scrollbar {
    width: 4px;
    
}
/* Track */
.menuScroll::-webkit-scrollbar-track {
    background: transparent;
}
/* Handle */
.menuScroll::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background-color: #C6C8CC;
    cursor: pointer;
}

.textOverflow {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.flexPrice {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
}
