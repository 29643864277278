.sideBar {
    height: 100vh;
    position: fixed;
    z-index: 99;
    transition: 0.6s ease-in-out;
}
.menuList {
    width: 100%;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
}
.sideMenuBar {
    position: absolute;
    height: 100vh;
    overflow: hidden;
    transition: 0.6s ease-in-out;
    z-index: 99;
}
.sideMenuList {
    padding-top: 40px;
    padding-left: 22px;
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
}
.link {
    display: block;
    width: 100%;
    color: #818181;
    font-weight: 500;
    white-space: nowrap;
    font-size: 16px;
    line-height: 24px;
    &:hover {
        color: white;
    }
}
.linkActive {
    display: block;
    width: 100%;
    color: white;
    font-weight: 700;
    white-space: nowrap;
    font-size: 16px;
    line-height: 24px;
}
.settingsMenu {
    width: 90px;
    height: 75px;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 36px;
    border-radius: 5px;
    cursor: pointer;
}
.invert {
    filter: invert(100%);
}
