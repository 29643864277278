.stepper .MuiStep-root,
.stepper .MuiStepLabel-iconContainer {
  padding: 0;
}

.stepCompleted:not(.stepActive) {
  cursor: pointer;
}

.stepCompleted:not(.stepActive)+div>.MuiStepConnector-line {
  border-image: linear-gradient(270deg, #902F8C 4.58%, #892d8e 100%);
  border-image-slice: 1;
}

.stepper .MuiStepConnector-line {
  border: 1px solid #fff;
}

.stepCompleted:not(.stepActive) + .Mui-active {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: calc(50% - 9px); 
    left: 15%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-style: solid;
  }

  &:before {
    border:solid #892d8e;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 8px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
}

.stepCompleted:not(.stepActive) + .Mui-active span {
  border: 1px solid;
  border-image: linear-gradient(270deg, #fff 65%, #892d8e 30%);
  border-image-slice: 1;
}


.serviceField svg {
  z-index: 7;
}

.serviceField fieldset {
  border: none;
  background: #EBEBEB;
}