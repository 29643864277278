.filterSection {
    display: flex;
    align-items: self-end;
    justify-content: flex-end;
    gap: 15px;
}

.header {
    // box-shadow: 0px 4px 5px 0px rgba(112, 144, 176, 0.10);
    background-color: #fff;
    overflow: hidden;
    border-radius: 20px;
}

.inputFilter {
    display: flex;
    gap: 10px;
}

.flexJustify {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 24px;
    padding-bottom: 15px;
}

.flex {
    display: flex;
    align-items: center;
    gap: 15px;
}

.mobilePreview {
    display: flex;
    align-items: center;
    gap: 5px;
}

.box {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    width: 33px;
    height: 33px;
    cursor: pointer;
}

.banner {
    height: 245px;
    border-radius: 20px;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    border: 5px solid #FFF;
}

.bannerAdd {
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    background-color: rgba(2, 2, 2, 0.8);
    padding: 5px 13px;
    cursor: pointer;

    .bannerAddText {
        color: #B3B3B3;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 1;
    }
}

.logoBox {
    border-radius: 50%;
    background-color: #000;
    // box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
    border: 4px solid #fff;
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.logoContainer {
    position: relative;
}

.logoMain {
    display: flex;
    align-items: center;
    gap: 20px;
}

.addlogo {
    position: absolute;
    width: 30px;
    height: 30px;
    background-color: #000;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    bottom: 10px;
    right: 0;
    background-color: #efefef;
    border: 0.8px solid #9e9e9e;
    cursor: pointer;
}

.text1 {
    font-size: 25px;
    font-weight: 600;
    color: #000;
}

.text2 {
    font-size: 15px;
    font-weight: 600;
    color: #414141;
}

.containerLogo {
    padding: 0 30px;
    margin-top: -20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.flexBox {
    display: flex;
    gap: 10px;
}

.editInput {
    border: 1px solid #a3a3a3;
    border-radius: 3px;
    padding: 3px 2px;

    &:focus-visible {
        outline: 0;
    }
}

@media (max-width: 991.98px) {
    .text1 {
        font-size: 20px;
    }

    .logoBox {
        width: 100px;
        height: 100px;
    }

    .containerLogo {
        flex-wrap: wrap;
    }
}


.bannerContent {
    border-radius: 0px 0px 20px 20px;
    background: #FFF;
}