.close {
    position: absolute;
    right: 10px;
    top: 8px;
    cursor: pointer;
}

.title {
    padding-bottom: 25px;
    display: block;
}

.lable {
    display: block;
    color: #000;
    font-weight: 500;
    padding-bottom: 5px;
}

.modleContainer {
    .MuiPaper-rounded {
        border-radius: 10px;
    }
}
