.inputLabel {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.inputLabelText {
    color: #2E2E2E;
    font-size: 16px;
    font-weight: 590;
    margin-bottom: 5px;
    display: flex;
    gap: 5px;
}

.TextField {
    border-radius: 7px;
    border: 1px solid #C5C5C5;

    input {
        padding: 12px 14px;
    }

    input,
    textarea {
        color: #242424;
        font-size: 16px;
        font-weight: 500;
    }

    fieldset {
        border: none;
    }
}