.modal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.paperModal {
    position: relative;
    background-color: white;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.15);
    padding: 20px;
    width: 725px;
}

.title {
    color: #222;
    font-size: 24px;
    font-weight: 590;
    margin-bottom: 25px;
}

.close {
    position: absolute;
    right: 10px;
    top: 8px;
    font-size: 22px;
}

.button {
    width: 100%;
    line-height: 1;
}

.avatarEditorWrapper {
    width: 100% !important;
    height: auto !important;
    // background: lightgray -26px -15px / 108.215% 103.363% no-repeat;
    position: relative;
    background: rgba(0, 0, 0, 0.70);
    display: flex;
    justify-content: center;
}

.dragToReposition {
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    margin: 0 auto;
    border-radius: 10px;
    border: 1px solid #FFF;
    background: rgba(0, 0, 0, 0.70);
    color: #FFF;
    font-size: 18px;
    font-weight: 510;
    justify-content: space-evenly;
    width: 260px;
    padding: 5px;
}

.icon {
    cursor: pointer;
}