.close {
    position: absolute;
    top: 16px;
    right: 17px;
}

.title {
    display: block;
    color: #222222;
}

.imageCrop {
    border: 1px solid #c5c5c5;
}

.title2 {
    font-size: 19px;
    font-weight: 600;
    color: #1f1f1f;
}

.para {
    font-size: 14px;
    font-weight: 500;
    color: #424242;
}

.imgBox {
    display: flex;
    gap: 15px;

    .imgBlack {
        position: relative;
        border: 1px solid #c5c5c5;
        width: 126px;
        height: 126px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .imgWhite {
        position: relative;
        border: 1px solid #c5c5c5;
        width: 126px;
        height: 126px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.flexRow {
    display: flex;
    gap: 20px;
}

.imgView {
    width: 100%;
}

.subtitle {
    font-size: 17px;
    font-weight: 500;
    color: #222;
}

.imageSize {
    width: 100%;
    height: 100%;
    // object-fit: contain;
}

.ImageSelect {
    border-radius: 5px;
    height: 100%;
    overflow: hidden;
    box-shadow: 0 0 6px 0px #0000006b;
    border: 3px solid transparent;
    transition: 0.5s ease;
    position: relative;
}

.slected {
    border: 3px solid #0073c0;
}

.removeCoverImage {
    position: absolute;
    right: 0;
    top: 0;
    background: #3535436e;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    cursor: pointer;
}

.coverImageUpload {
    border: 1px solid #e6e6e6;
    padding: 15px;
    border-radius: 5px;
    margin-top: 15px;
}

.coverImageUpload {
    display: flex;
    gap: 25px;
    align-items: center;

    .imagePreviewArea {
        width: 330px;
        height: 150px;
        border: 1px solid #e6e6e6;
        border-radius: 5px;
    }
    img {
        width: 100%;
        height: 100%;
    }
}