.container {
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    padding: 20px 25px;
}

.containerHeight {
    padding-top: 70px;
    height: 100%;
}

.backgroundBg {
    height: 245px;
    background-position: center;
    object-fit: cover;
    background-size: cover;
}

.innerContainer {
    border-radius: 15px;
    background-color: #fff;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 25px 30px;
    max-width: 600px;
    margin: 0 auto;
    margin-top: -50px;
    padding-top: 70px;
}

.title {
    font-size: 22px;
    display: block;
    text-align: center;
    color: #000;
    font-weight: 600;
}

.logoBox {
    height: 110px;
    width: 110px;
    border-radius: 50%;
    background-color: #000;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
    border: 4px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -35%;
    left: 50%;
    transform: translate(-50%, 0%);
}

.header {
    background-color: #000;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
    padding: 15px 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
}

.backText {
    font-size: 16px;
    font-weight: 600;
}

.flexBox {
    display: flex;
    align-items: center;
    gap: 5px;
}

.flexContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.titleHead {
    font-size: 25px;
    font-weight: 600;
    line-height: 1;
}

.userName {
    font-weight: 600;
    font-size: 15px;
    line-height: 1;
    padding-top: 5px;
}

.headFlex {
    display: flex;
    align-items: center;
    gap: 20px;
}


.projectStatusInfo {
    border-radius: 10px;
    padding: 15px 20px;
    color: #FFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media(max-width:767px) {
    .projectStatusInfo {
        align-items: flex-end;
    }

    .statusDateTime {
        flex-direction: column;
        align-items: flex-start;
    }
}