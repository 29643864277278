.subTitle1 {
  color: #1F1F1F;
  font-size: 16px;
  font-weight: 590;
}

.subTitle2 {
  color: #818181;
  font-size: 12px;
  font-weight: 590;
}

.jobDetail {
  border-radius: 7px;
  border: 1px solid #C5C5C5;
  background: #FFF;
  padding: 5px;
  margin-top: 15px;
}

.jobDetailHeader {
  border-radius: 5px;
  background: #F6F6F6;
  display: flex;
  justify-content: space-between;
  padding: 17px 25px;
}

.jobTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .subTitle2 {
    color: #2E2E2E;
    margin-left: 10px;
    line-height: 1;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .subTitle2::before {
    content: "";
    height: 5px;
    width: 5px;
    display: inline-block;
    background: #2E2E2E;
    border-radius: 50%;
    vertical-align: middle;
    margin-top: 1px;
  }
}

.jobServiceType {
  padding: 17px;

  .service_type {
    color: #000;
    font-size: 16px;
    font-weight: 590;
  }

  .jobServiceInfo {
    border-radius: 7px;
    border: 1px solid #C5C5C5;
    background: #FFF;
    padding: 17px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .serviceName {
    color: #000;
    font-size: 14px;
    font-weight: 590;
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .serviceFilm {
    color: #424242;
    font-size: 12px;
    font-weight: 590;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .serviceFilm::before {
    content: '';
    height: 5px;
    width: 5px;
    background: #000;
    border-radius: 50%;
    left: -15px;
    top: 6px;
  }

  .servicePriceTitle {
    font-size: 14px;
    font-weight: 510;
    color: #424242;
  }

  .servicePrice {
    text-align: right;
    font-size: 24px;
    font-weight: 700;
    padding-left: 15px;
  }
}

.contacInfo {
  border-radius: 7px;
  border: 1px solid #C5C5C5;
  padding: 10px 23px;
}

.flexWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.contactInfoWrapper {
  display: flex;
}

.contactDetail {
  margin-left: 6px !important;
  font-weight: 510;
  text-wrap: nowrap;
}

.removalWrapper {
  align-items: center;
  display: flex;
  gap: 10px;
  color: #000;
  font-size: 14px;
  font-weight: 590;
}

.titleJobId {
  color: #000;
  font-size: 14px;
  font-weight: 590;
}

.serviceLongQuotePrice {
  border-radius: 7px;
  background: #F6F6F6;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  margin-top: 25px;

  .createNewItem {
    color: #0085DD;
    font-size: 14px;
    font-weight: 510;
    text-transform: capitalize;
  }

  .servicePrice {
    color: #000;
    font-size: 16px;
    font-weight: 700;
  }
}

.jobDetailHeaderLongQuote {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #DBDBDB;
  margin: 0 20px;
}

.serviceSubPriceTitle,
.servicePriceTitle {
  color: #424242;
  font-size: 14px;
  font-weight: 510;
}

.serviceSubPrice {
  text-align: right;
  color: #000;
  font-size: 18px;
  font-weight: 700;
  padding-left: 15px;
}