.tabContainer {
    display: flex;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.10);
    border: 3px solid #FFF;

    .tabBox {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        padding: 8px 0;
        position: relative;
        cursor: pointer;
        border-radius: 20px;
        flex: 1;
    }

    .select {
        background: #F3F4FF;
    }

    .tabText {
        font-size: 15px;
        font-weight: 500;
        color: #2e2e2e;
    }
}

@media(max-width:767px) {
    .tabContainer {
        .tabBox {
            flex-direction: column;
        }
    }
}